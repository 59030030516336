import React from 'react';

function Highlight(props) {
  let backgroundColour = '#16556e'; // Medipass blue
  let textColour = '#FFF';

  if (props.backgroundColour === 'blue') {
    backgroundColour = '#0055FF';
  } else if (props.backgroundColour === 'green') {
    backgroundColour = '#9BCA53';
  } else if (props.backgroundColour === 'babyblue') {
    backgroundColour = '#7AC5CD';
  } else if (props.backgroundColour === 'grey') {
    backgroundColour = '#dae9f2';
  }

  if (props.textColour === 'dark') {
    textColour = '#111';
  }

  const styles = {
    color: textColour,
    display: 'inline',
    'background-color': backgroundColour,
    padding: '3px',
    'border-radius': '3px'
  };

  return <p style={styles}>{props.children}</p>;
}

export default Highlight;

// Example
// <Highlight backgroundColour="grey" textColour="dark">Bla Bla</Highlight>
// grey not dark enough

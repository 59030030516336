// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sdk-comcare-callbacks-mdx": () => import("./../../../src/pages/sdk/comcare/callbacks.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-callbacks-mdx" */),
  "component---src-pages-sdk-comcare-cancelling-claims-mdx": () => import("./../../../src/pages/sdk/comcare/cancelling-claims.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-cancelling-claims-mdx" */),
  "component---src-pages-sdk-comcare-claim-flows-and-states-mdx": () => import("./../../../src/pages/sdk/comcare/claim-flows-and-states.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-claim-flows-and-states-mdx" */),
  "component---src-pages-sdk-comcare-claims-processing-flows-mdx": () => import("./../../../src/pages/sdk/comcare/claims-processing-flows.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-claims-processing-flows-mdx" */),
  "component---src-pages-sdk-comcare-comcare-attributes-mdx": () => import("./../../../src/pages/sdk/comcare/comcare-attributes.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-comcare-attributes-mdx" */),
  "component---src-pages-sdk-comcare-going-live-mdx": () => import("./../../../src/pages/sdk/comcare/going-live.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-going-live-mdx" */),
  "component---src-pages-sdk-comcare-integration-options-mdx": () => import("./../../../src/pages/sdk/comcare/integration-options.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-integration-options-mdx" */),
  "component---src-pages-sdk-comcare-introduction-to-comcare-mdx": () => import("./../../../src/pages/sdk/comcare/Introduction-to-comcare.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-introduction-to-comcare-mdx" */),
  "component---src-pages-sdk-comcare-optional-settings-mdx": () => import("./../../../src/pages/sdk/comcare/optional-settings.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-optional-settings-mdx" */),
  "component---src-pages-sdk-comcare-playground-and-sample-transactions-mdx": () => import("./../../../src/pages/sdk/comcare/playground-and-sample-transactions.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-playground-and-sample-transactions-mdx" */),
  "component---src-pages-sdk-comcare-response-mdx": () => import("./../../../src/pages/sdk/comcare/response.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-response-mdx" */),
  "component---src-pages-sdk-comcare-security-mdx": () => import("./../../../src/pages/sdk/comcare/security.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-security-mdx" */),
  "component---src-pages-sdk-comcare-submission-mdx": () => import("./../../../src/pages/sdk/comcare/submission.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-submission-mdx" */),
  "component---src-pages-sdk-comcare-submitting-claims-mdx": () => import("./../../../src/pages/sdk/comcare/submitting-claims.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-submitting-claims-mdx" */),
  "component---src-pages-sdk-comcare-testing-mdx": () => import("./../../../src/pages/sdk/comcare/testing.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-testing-mdx" */),
  "component---src-pages-sdk-comcare-transaction-status-receipts-statements-mdx": () => import("./../../../src/pages/sdk/comcare/transaction-status,-receipts,-statements.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-transaction-status-receipts-statements-mdx" */),
  "component---src-pages-sdk-comcare-tyro-health-sdk-setup-mdx": () => import("./../../../src/pages/sdk/comcare/tyro-health-sdk-setup.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-tyro-health-sdk-setup-mdx" */),
  "component---src-pages-sdk-comcare-webhooks-mdx": () => import("./../../../src/pages/sdk/comcare/webhooks.mdx" /* webpackChunkName: "component---src-pages-sdk-comcare-webhooks-mdx" */),
  "component---src-pages-sdk-index-mdx": () => import("./../../../src/pages/sdk/index.mdx" /* webpackChunkName: "component---src-pages-sdk-index-mdx" */),
  "component---src-pages-sdk-medicare-dva-direct-claims-api-about-the-apis-mdx": () => import("./../../../src/pages/sdk/medicare-dva-direct-claims-api/about-the-apis.mdx" /* webpackChunkName: "component---src-pages-sdk-medicare-dva-direct-claims-api-about-the-apis-mdx" */),
  "component---src-pages-sdk-medicare-dva-direct-claims-api-authentication-mdx": () => import("./../../../src/pages/sdk/medicare-dva-direct-claims-api/authentication.mdx" /* webpackChunkName: "component---src-pages-sdk-medicare-dva-direct-claims-api-authentication-mdx" */),
  "component---src-pages-sdk-medicare-dva-direct-claims-api-cancellations-mdx": () => import("./../../../src/pages/sdk/medicare-dva-direct-claims-api/cancellations.mdx" /* webpackChunkName: "component---src-pages-sdk-medicare-dva-direct-claims-api-cancellations-mdx" */),
  "component---src-pages-sdk-medicare-dva-direct-claims-api-going-live-mdx": () => import("./../../../src/pages/sdk/medicare-dva-direct-claims-api/going-live.mdx" /* webpackChunkName: "component---src-pages-sdk-medicare-dva-direct-claims-api-going-live-mdx" */),
  "component---src-pages-sdk-medicare-dva-direct-claims-api-identifying-yourself-mdx": () => import("./../../../src/pages/sdk/medicare-dva-direct-claims-api/identifying-yourself.mdx" /* webpackChunkName: "component---src-pages-sdk-medicare-dva-direct-claims-api-identifying-yourself-mdx" */),
  "component---src-pages-sdk-medicare-dva-direct-claims-api-reference-data-mdx": () => import("./../../../src/pages/sdk/medicare-dva-direct-claims-api/reference-data.mdx" /* webpackChunkName: "component---src-pages-sdk-medicare-dva-direct-claims-api-reference-data-mdx" */),
  "component---src-pages-sdk-medicare-dva-direct-claims-api-reports-and-printed-statements-mdx": () => import("./../../../src/pages/sdk/medicare-dva-direct-claims-api/reports-and-printed-statements.mdx" /* webpackChunkName: "component---src-pages-sdk-medicare-dva-direct-claims-api-reports-and-printed-statements-mdx" */),
  "component---src-pages-sdk-medicare-dva-direct-claims-api-requests-and-responses-mdx": () => import("./../../../src/pages/sdk/medicare-dva-direct-claims-api/requests-and-responses.mdx" /* webpackChunkName: "component---src-pages-sdk-medicare-dva-direct-claims-api-requests-and-responses-mdx" */),
  "component---src-pages-sdk-medicare-dva-direct-claims-api-requests-dva-claims-mdx": () => import("./../../../src/pages/sdk/medicare-dva-direct-claims-api/requests-dva-claims.mdx" /* webpackChunkName: "component---src-pages-sdk-medicare-dva-direct-claims-api-requests-dva-claims-mdx" */),
  "component---src-pages-sdk-medicare-dva-direct-claims-api-requests-medicare-claims-mdx": () => import("./../../../src/pages/sdk/medicare-dva-direct-claims-api/requests-medicare-claims.mdx" /* webpackChunkName: "component---src-pages-sdk-medicare-dva-direct-claims-api-requests-medicare-claims-mdx" */),
  "component---src-pages-sdk-medicare-dva-direct-claims-api-requests-setting-webhooks-mdx": () => import("./../../../src/pages/sdk/medicare-dva-direct-claims-api/requests-setting-webhooks.mdx" /* webpackChunkName: "component---src-pages-sdk-medicare-dva-direct-claims-api-requests-setting-webhooks-mdx" */),
  "component---src-pages-sdk-medicare-dva-direct-claims-api-response-payload-mdx": () => import("./../../../src/pages/sdk/medicare-dva-direct-claims-api/response-payload.mdx" /* webpackChunkName: "component---src-pages-sdk-medicare-dva-direct-claims-api-response-payload-mdx" */),
  "component---src-pages-sdk-medicare-dva-direct-claims-api-setting-webhooks-mdx": () => import("./../../../src/pages/sdk/medicare-dva-direct-claims-api/setting-webhooks.mdx" /* webpackChunkName: "component---src-pages-sdk-medicare-dva-direct-claims-api-setting-webhooks-mdx" */),
  "component---src-pages-sdk-medicare-dva-direct-claims-api-support-mdx": () => import("./../../../src/pages/sdk/medicare-dva-direct-claims-api/support.mdx" /* webpackChunkName: "component---src-pages-sdk-medicare-dva-direct-claims-api-support-mdx" */),
  "component---src-pages-sdk-medicare-dva-direct-claims-api-testing-mdx": () => import("./../../../src/pages/sdk/medicare-dva-direct-claims-api/testing.mdx" /* webpackChunkName: "component---src-pages-sdk-medicare-dva-direct-claims-api-testing-mdx" */),
  "component---src-pages-sdk-medicare-dva-direct-claims-api-user-interface-mdx": () => import("./../../../src/pages/sdk/medicare-dva-direct-claims-api/user-interface.mdx" /* webpackChunkName: "component---src-pages-sdk-medicare-dva-direct-claims-api-user-interface-mdx" */),
  "component---src-pages-sdk-other-branding-mdx": () => import("./../../../src/pages/sdk/other/branding.mdx" /* webpackChunkName: "component---src-pages-sdk-other-branding-mdx" */),
  "component---src-pages-sdk-payment-authority-capturing-funder-mdx": () => import("./../../../src/pages/sdk/payment-authority/capturing-funder.mdx" /* webpackChunkName: "component---src-pages-sdk-payment-authority-capturing-funder-mdx" */),
  "component---src-pages-sdk-payment-authority-example-videos-js": () => import("./../../../src/pages/sdk/payment-authority/ExampleVideos.js" /* webpackChunkName: "component---src-pages-sdk-payment-authority-example-videos-js" */),
  "component---src-pages-sdk-payment-authority-getting-started-authorizing-payment-mdx": () => import("./../../../src/pages/sdk/payment-authority/getting-started/authorizing-payment.mdx" /* webpackChunkName: "component---src-pages-sdk-payment-authority-getting-started-authorizing-payment-mdx" */),
  "component---src-pages-sdk-payment-authority-getting-started-obtaining-token-mdx": () => import("./../../../src/pages/sdk/payment-authority/getting-started/obtaining-token.mdx" /* webpackChunkName: "component---src-pages-sdk-payment-authority-getting-started-obtaining-token-mdx" */),
  "component---src-pages-sdk-payment-authority-getting-started-setup-mdx": () => import("./../../../src/pages/sdk/payment-authority/getting-started/setup.mdx" /* webpackChunkName: "component---src-pages-sdk-payment-authority-getting-started-setup-mdx" */),
  "component---src-pages-sdk-payment-authority-index-mdx": () => import("./../../../src/pages/sdk/payment-authority/index.mdx" /* webpackChunkName: "component---src-pages-sdk-payment-authority-index-mdx" */),
  "component---src-pages-sdk-payment-authority-prepopulating-mdx": () => import("./../../../src/pages/sdk/payment-authority/prepopulating.mdx" /* webpackChunkName: "component---src-pages-sdk-payment-authority-prepopulating-mdx" */),
  "component---src-pages-sdk-payment-authority-submit-button-mdx": () => import("./../../../src/pages/sdk/payment-authority/submit-button.mdx" /* webpackChunkName: "component---src-pages-sdk-payment-authority-submit-button-mdx" */),
  "component---src-pages-sdk-playground-mdx": () => import("./../../../src/pages/sdk/playground.mdx" /* webpackChunkName: "component---src-pages-sdk-playground-mdx" */),
  "component---src-pages-sdk-sdk-reference-claim-items-mdx": () => import("./../../../src/pages/sdk/sdk-reference/claim-items.mdx" /* webpackChunkName: "component---src-pages-sdk-sdk-reference-claim-items-mdx" */),
  "component---src-pages-sdk-sdk-reference-constants-mdx": () => import("./../../../src/pages/sdk/sdk-reference/constants.mdx" /* webpackChunkName: "component---src-pages-sdk-sdk-reference-constants-mdx" */),
  "component---src-pages-sdk-sdk-reference-index-mdx": () => import("./../../../src/pages/sdk/sdk-reference/index.mdx" /* webpackChunkName: "component---src-pages-sdk-sdk-reference-index-mdx" */),
  "component---src-pages-sdk-sdk-reference-members-mdx": () => import("./../../../src/pages/sdk/sdk-reference/members.mdx" /* webpackChunkName: "component---src-pages-sdk-sdk-reference-members-mdx" */),
  "component---src-pages-sdk-sdk-reference-providers-mdx": () => import("./../../../src/pages/sdk/sdk-reference/providers.mdx" /* webpackChunkName: "component---src-pages-sdk-sdk-reference-providers-mdx" */),
  "component---src-pages-sdk-sdk-reference-setup-mdx": () => import("./../../../src/pages/sdk/sdk-reference/setup.mdx" /* webpackChunkName: "component---src-pages-sdk-sdk-reference-setup-mdx" */),
  "component---src-pages-sdk-sdk-reference-transactions-mdx": () => import("./../../../src/pages/sdk/sdk-reference/transactions.mdx" /* webpackChunkName: "component---src-pages-sdk-sdk-reference-transactions-mdx" */),
  "component---src-pages-sdk-sdk-reference-views-mdx": () => import("./../../../src/pages/sdk/sdk-reference/views.mdx" /* webpackChunkName: "component---src-pages-sdk-sdk-reference-views-mdx" */),
  "component---src-pages-sdk-transaction-bridge-mdx": () => import("./../../../src/pages/sdk/transaction/bridge.mdx" /* webpackChunkName: "component---src-pages-sdk-transaction-bridge-mdx" */),
  "component---src-pages-sdk-transaction-callbacks-mdx": () => import("./../../../src/pages/sdk/transaction/callbacks.mdx" /* webpackChunkName: "component---src-pages-sdk-transaction-callbacks-mdx" */),
  "component---src-pages-sdk-transaction-getting-started-optional-settings-mdx": () => import("./../../../src/pages/sdk/transaction/getting-started/optional-settings.mdx" /* webpackChunkName: "component---src-pages-sdk-transaction-getting-started-optional-settings-mdx" */),
  "component---src-pages-sdk-transaction-getting-started-record-mdx": () => import("./../../../src/pages/sdk/transaction/getting-started/record.mdx" /* webpackChunkName: "component---src-pages-sdk-transaction-getting-started-record-mdx" */),
  "component---src-pages-sdk-transaction-getting-started-setup-mdx": () => import("./../../../src/pages/sdk/transaction/getting-started/setup.mdx" /* webpackChunkName: "component---src-pages-sdk-transaction-getting-started-setup-mdx" */),
  "component---src-pages-sdk-transaction-getting-started-submit-mdx": () => import("./../../../src/pages/sdk/transaction/getting-started/submit.mdx" /* webpackChunkName: "component---src-pages-sdk-transaction-getting-started-submit-mdx" */),
  "component---src-pages-sdk-transaction-glossary-mdx": () => import("./../../../src/pages/sdk/transaction/glossary.mdx" /* webpackChunkName: "component---src-pages-sdk-transaction-glossary-mdx" */),
  "component---src-pages-sdk-transaction-index-mdx": () => import("./../../../src/pages/sdk/transaction/index.mdx" /* webpackChunkName: "component---src-pages-sdk-transaction-index-mdx" */),
  "component---src-pages-sdk-transaction-item-codes-mdx": () => import("./../../../src/pages/sdk/transaction/item-codes.mdx" /* webpackChunkName: "component---src-pages-sdk-transaction-item-codes-mdx" */),
  "component---src-pages-sdk-transaction-prepopulating-mdx": () => import("./../../../src/pages/sdk/transaction/prepopulating.mdx" /* webpackChunkName: "component---src-pages-sdk-transaction-prepopulating-mdx" */),
  "component---src-pages-sdk-transaction-reporting-mdx": () => import("./../../../src/pages/sdk/transaction/reporting.mdx" /* webpackChunkName: "component---src-pages-sdk-transaction-reporting-mdx" */),
  "component---src-pages-sdk-transaction-support-mdx": () => import("./../../../src/pages/sdk/transaction/support.mdx" /* webpackChunkName: "component---src-pages-sdk-transaction-support-mdx" */),
  "component---src-pages-sdk-transaction-supported-funders-mdx": () => import("./../../../src/pages/sdk/transaction/supported-funders.mdx" /* webpackChunkName: "component---src-pages-sdk-transaction-supported-funders-mdx" */),
  "component---src-pages-sdk-transaction-test-data-mdx": () => import("./../../../src/pages/sdk/transaction/test-data.mdx" /* webpackChunkName: "component---src-pages-sdk-transaction-test-data-mdx" */),
  "component---src-pages-sdk-transaction-view-types-mdx": () => import("./../../../src/pages/sdk/transaction/view-types.mdx" /* webpackChunkName: "component---src-pages-sdk-transaction-view-types-mdx" */),
  "component---src-pages-sdk-transaction-webhooks-mdx": () => import("./../../../src/pages/sdk/transaction/webhooks.mdx" /* webpackChunkName: "component---src-pages-sdk-transaction-webhooks-mdx" */),
  "component---src-pages-sdk-tyro-eftpos-healthpoint-callbacks-mdx": () => import("./../../../src/pages/sdk/tyro-eftpos-healthpoint/callbacks.mdx" /* webpackChunkName: "component---src-pages-sdk-tyro-eftpos-healthpoint-callbacks-mdx" */),
  "component---src-pages-sdk-tyro-eftpos-healthpoint-eftpos-attributes-mdx": () => import("./../../../src/pages/sdk/tyro-eftpos-healthpoint/eftpos-attributes.mdx" /* webpackChunkName: "component---src-pages-sdk-tyro-eftpos-healthpoint-eftpos-attributes-mdx" */),
  "component---src-pages-sdk-tyro-eftpos-healthpoint-eftpos-transactions-mdx": () => import("./../../../src/pages/sdk/tyro-eftpos-healthpoint/eftpos-transactions.mdx" /* webpackChunkName: "component---src-pages-sdk-tyro-eftpos-healthpoint-eftpos-transactions-mdx" */),
  "component---src-pages-sdk-tyro-eftpos-healthpoint-going-live-mdx": () => import("./../../../src/pages/sdk/tyro-eftpos-healthpoint/going-live.mdx" /* webpackChunkName: "component---src-pages-sdk-tyro-eftpos-healthpoint-going-live-mdx" */),
  "component---src-pages-sdk-tyro-eftpos-healthpoint-healthpoint-attributes-mdx": () => import("./../../../src/pages/sdk/tyro-eftpos-healthpoint/healthpoint-attributes.mdx" /* webpackChunkName: "component---src-pages-sdk-tyro-eftpos-healthpoint-healthpoint-attributes-mdx" */),
  "component---src-pages-sdk-tyro-eftpos-healthpoint-healthpoint-support-mdx": () => import("./../../../src/pages/sdk/tyro-eftpos-healthpoint/healthpoint-support.mdx" /* webpackChunkName: "component---src-pages-sdk-tyro-eftpos-healthpoint-healthpoint-support-mdx" */),
  "component---src-pages-sdk-tyro-eftpos-healthpoint-healthpoint-transactions-mdx": () => import("./../../../src/pages/sdk/tyro-eftpos-healthpoint/healthpoint-transactions.mdx" /* webpackChunkName: "component---src-pages-sdk-tyro-eftpos-healthpoint-healthpoint-transactions-mdx" */),
  "component---src-pages-sdk-tyro-eftpos-healthpoint-introduction-to-tyro-eftpos-and-healthpoint-mdx": () => import("./../../../src/pages/sdk/tyro-eftpos-healthpoint/introduction-to-tyro-eftpos-and-healthpoint.mdx" /* webpackChunkName: "component---src-pages-sdk-tyro-eftpos-healthpoint-introduction-to-tyro-eftpos-and-healthpoint-mdx" */),
  "component---src-pages-sdk-tyro-eftpos-healthpoint-playground-and-sample-transactions-mdx": () => import("./../../../src/pages/sdk/tyro-eftpos-healthpoint/playground-and-sample-transactions.mdx" /* webpackChunkName: "component---src-pages-sdk-tyro-eftpos-healthpoint-playground-and-sample-transactions-mdx" */),
  "component---src-pages-sdk-tyro-eftpos-healthpoint-receipt-and-statement-requirements-mdx": () => import("./../../../src/pages/sdk/tyro-eftpos-healthpoint/receipt-and-statement-requirements.mdx" /* webpackChunkName: "component---src-pages-sdk-tyro-eftpos-healthpoint-receipt-and-statement-requirements-mdx" */),
  "component---src-pages-sdk-tyro-eftpos-healthpoint-supported-eftpos-payment-types-mdx": () => import("./../../../src/pages/sdk/tyro-eftpos-healthpoint/supported-eftpos-payment-types.mdx" /* webpackChunkName: "component---src-pages-sdk-tyro-eftpos-healthpoint-supported-eftpos-payment-types-mdx" */),
  "component---src-pages-sdk-tyro-eftpos-healthpoint-testing-mdx": () => import("./../../../src/pages/sdk/tyro-eftpos-healthpoint/testing.mdx" /* webpackChunkName: "component---src-pages-sdk-tyro-eftpos-healthpoint-testing-mdx" */),
  "component---src-pages-sdk-tyro-eftpos-healthpoint-tyro-integration-alternatives-mdx": () => import("./../../../src/pages/sdk/tyro-eftpos-healthpoint/tyro-integration-alternatives.mdx" /* webpackChunkName: "component---src-pages-sdk-tyro-eftpos-healthpoint-tyro-integration-alternatives-mdx" */),
  "component---src-pages-sdk-tyro-eftpos-healthpoint-tyro-machines-mdx": () => import("./../../../src/pages/sdk/tyro-eftpos-healthpoint/tyro-machines.mdx" /* webpackChunkName: "component---src-pages-sdk-tyro-eftpos-healthpoint-tyro-machines-mdx" */),
  "component---src-pages-sdk-tyro-eftpos-healthpoint-webhooks-mdx": () => import("./../../../src/pages/sdk/tyro-eftpos-healthpoint/webhooks.mdx" /* webpackChunkName: "component---src-pages-sdk-tyro-eftpos-healthpoint-webhooks-mdx" */),
  "component---src-pages-sdk-worksafe-vic-callbacks-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/callbacks.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-callbacks-mdx" */),
  "component---src-pages-sdk-worksafe-vic-cancelling-claims-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/cancelling-claims.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-cancelling-claims-mdx" */),
  "component---src-pages-sdk-worksafe-vic-claim-flows-and-states-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/claim-flows-and-states.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-claim-flows-and-states-mdx" */),
  "component---src-pages-sdk-worksafe-vic-claims-processing-flows-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/claims-processing-flows.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-claims-processing-flows-mdx" */),
  "component---src-pages-sdk-worksafe-vic-going-live-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/going-live.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-going-live-mdx" */),
  "component---src-pages-sdk-worksafe-vic-integration-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/Integration.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-integration-mdx" */),
  "component---src-pages-sdk-worksafe-vic-integration-options-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/integration-options.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-integration-options-mdx" */),
  "component---src-pages-sdk-worksafe-vic-introduction-to-worksafe-victoria-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/Introduction-to-Worksafe-Victoria.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-introduction-to-worksafe-victoria-mdx" */),
  "component---src-pages-sdk-worksafe-vic-optional-settings-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/optional-settings.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-optional-settings-mdx" */),
  "component---src-pages-sdk-worksafe-vic-playground-and-sample-transactions-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/playground-and-sample-transactions.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-playground-and-sample-transactions-mdx" */),
  "component---src-pages-sdk-worksafe-vic-response-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/response.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-response-mdx" */),
  "component---src-pages-sdk-worksafe-vic-security-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/security.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-security-mdx" */),
  "component---src-pages-sdk-worksafe-vic-submission-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/submission.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-submission-mdx" */),
  "component---src-pages-sdk-worksafe-vic-submitting-claims-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/submitting-claims.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-submitting-claims-mdx" */),
  "component---src-pages-sdk-worksafe-vic-testing-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/testing.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-testing-mdx" */),
  "component---src-pages-sdk-worksafe-vic-transaction-status-receipts-statements-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/transaction-status,-receipts,-statements.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-transaction-status-receipts-statements-mdx" */),
  "component---src-pages-sdk-worksafe-vic-webhooks-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/webhooks.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-webhooks-mdx" */),
  "component---src-pages-sdk-worksafe-vic-worksafe-victoria-attributes-mdx": () => import("./../../../src/pages/sdk/worksafe-vic/worksafe-victoria-attributes.mdx" /* webpackChunkName: "component---src-pages-sdk-worksafe-vic-worksafe-victoria-attributes-mdx" */)
}


import React from 'react';
import { BiCopy } from 'react-icons/bi';

function ClipboardCopy(props) {
  function copyToClipboard() {
    navigator.clipboard.writeText(props.text);
  }

  return <BiCopy onClick={copyToClipboard} />;
}

export default ClipboardCopy;

import React from 'react';
import { Box, Heading, LayoutSet } from 'fannypack';

export default function ExampleVideos() {
  return (
    <LayoutSet isHorizontal>
      <Box>
        <Heading use="h4">Existing user</Heading>
        <video controls width="100%" src="/images/existinguser.mov" type="video/mov" />
      </Box>
      <Box>
        <Heading use="h4">New user</Heading>
        <video controls width="100%" src="/images/newuser.mov" type="video/mov" />
      </Box>
    </LayoutSet>
  );
}

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';

import { ThemeProvider } from 'fannypack';
import theme from './src/theme';

export const wrapRootElement = ({ element, ...props }) => <ThemeProvider theme={theme}>{element}</ThemeProvider>;

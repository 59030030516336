import {
  faArrowLeft,
  faArrowRight,
  faBars,
  faIgloo,
  faSearch,
  faClipboard,
  faChevronDown,
  faChevronUp,
  faThumbsUp,
  faPen,
  faLongArrowAltRight,
  faFile,
  faComments,
  faUser,
  faHandPaper,
  faFileSignature,
  faShare,
  faExternalLinkAlt,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { css, palette } from 'fannypack';
import themes from 'fannypack/themes';
import _get from 'lodash/get';

export default {
  ...themes.medipass,
  webFontLoader: {
    google: {
      families: [..._get(themes, 'medipass.webFontLoader.google.families', []), 'Merriweather:700']
    }
  },
  global: {
    ..._get(themes, 'medipass.global'),
    base: css`
      .token {
        &.tag {
          color: ${palette('primary')};
        }

        &.punctuation {
          color: ${palette('text')};
        }

        &.string {
          color: ${palette('text')};
        }

        &.operator {
          color: ${palette('secondary')};
        }

        &.attr-name {
          color: ${palette('secondary')};
        }

        &.attr-value {
          color: ${palette('text')};
        }
      }
    `
  },
  Code: {
    base: css`
      & span {
        white-space: pre-wrap;
        overflow-wrap: break-word;
      }
    `
  },
  Icon: {
    iconSets: [
      {
        icons: [
          faArrowLeft,
          faArrowRight,
          faBars,
          faIgloo,
          faSearch,
          faClipboard,
          faChevronDown,
          faChevronUp,
          faThumbsUp,
          faPen,
          faLongArrowAltRight,
          faFile,
          faComments,
          faUser,
          faHandPaper,
          faShare,
          faFileSignature,
          faExternalLinkAlt,
          faTrashAlt
        ],
        prefix: 'solid-',
        type: 'font-awesome'
      }
    ]
  },
  Heading: {
    base: css`
      font-family: Merriweather, serif;
    `
  },
  Page: {
    ..._get(themes, 'medipass.Page'),
    WithSidebar: {
      ..._get(themes, 'medipass.Page.WithSidebar'),
      Sidebar: css`
        background-color: ${palette('white700')};
      `
    }
  }
};

import React from 'react';

function ColouredText(props) {
  let textColour = '#FF5500'; // Red

  if (props.textColour === 'blue') {
    textColour = '#0055FF';
  } else if (props.textColour === 'green') {
    textColour = '#00FF55';
  } else if (props.textColour === 'orange') {
    textColour = '#FFA500';
  }

  return <p style={{ color: textColour, display: 'inline' }}>{props.children}</p>;
}

export default ColouredText;

import React from 'react';

function NumberLabel(props) {
  const number = props.number;
  const hpadding = '8px';
  const vpadding = '5px';

  const styles = {
    color: '#777',
    display: 'inline',
    'background-color': '#e6e9eb',
    'padding-top': vpadding,
    'padding-bottom': vpadding,
    'padding-left': hpadding,
    'padding-right': hpadding,
    'border-radius': '20px',
    'font-size': '70%'
  };
  const textStyles = {
    display: 'inline'
  };

  const divStyles = {
    'margin-bottom': '5px'
  };

  return (
    <div style={divStyles}>
      <p style={styles}>{number}</p>
      <p style={textStyles}>{props.children}</p>
    </div>
  );
}

export default NumberLabel;

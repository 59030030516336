import { Divider } from 'fannypack';
import ColouredText from "../../../../src/components/worksafe-vic/ColouredText";
import Highlight from "../../../../src/components/worksafe-vic/Highlight";
import NumberLabel from "../../../../src/components/worksafe-vic/NumberLabel";
import * as React from 'react';
export default {
  Divider,
  ColouredText,
  Highlight,
  NumberLabel,
  React
};